/*
 |-----------------------------------------------------------------------------
 | base/_selection.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

::-moz-selection {
	background-color: $brand-2;
	color: $brand-1;
}

::selection {
	background-color: $brand-2;
	color: $brand-1;
}
