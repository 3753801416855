/*
 |-----------------------------------------------------------------------------
 | base/_paragraph.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

p {
	font-size: type(23px, $font-size-body);
	line-height: 1.4;
	margin: 0;
}
