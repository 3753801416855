/*
 |-----------------------------------------------------------------------------
 | base/_hr.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

hr {
	background-color: $brand-1;
	border: 0;
	height: 2px;
	margin: 0 auto;
	width: 100%;
}
