/*
 |-----------------------------------------------------------------------------
 | helpers/_utilities.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 | Contents
 |
 |  1. Blur                                                             LN:  19
 |  2. Screen reader                                                    LN:  41
 |  3. Spacing                                                          LN:  61
 |
 */

/*
 |-----------------------------------------------------------------------------
 | Blur
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

.u-blur {
	@include blur(5px);
	@include transition('filter');

	&.lazyloaded {
		@include blur(0);
	}
}

/*
 |-----------------------------------------------------------------------------
 | Screen reader
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

.u-screen-reader {
	height: 1px;
	overflow: hidden;
	position: absolute;
	width: 1px;
}

/*
 |-----------------------------------------------------------------------------
 | Spacing
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

.u-spacing {
	> * + * {
		margin-top: var(--space);
	}
}
