/*
 |-----------------------------------------------------------------------------
 | base/_headings.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

h1,
h2,
h3,
h4,
h5 {
	@include font-family('stack-1');
	color: $brand-1;
	margin: 0;
}

h1 {
	@include font-weight('bold');
	font-size: type(88.38px, $font-size-h1);
}

h2 {
	@include font-weight('bold');
	font-size: type(60px, $font-size-h3);
}

h3 {
	@include font-weight('medium');
	font-size: type(30px, 26px);
}

h4 {
	@include font-weight('medium');
	font-size: type(37.31px, $font-size-h4);
}

h5 {
	@include font-weight('medium');
	font-size: type(27.99px, $font-size-h5);
}
