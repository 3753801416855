/*
 |-----------------------------------------------------------------------------
 | components/_summary.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

summary {
	box-sizing: border-box;
	cursor: pointer;
	list-style-type: none;

	&:focus {
		outline: 0;
	}

	&::-webkit-details-marker {
		display: none;
	}
}
