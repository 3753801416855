/*
 |-----------------------------------------------------------------------------
 | base/_fields.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

input,
select,
textarea {
	appearance: none;
	border: 0;

	&:focus {
		outline: 0;
	}

	&:invalid {
		box-shadow: none;
	}
}
