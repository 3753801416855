/*
 |-----------------------------------------------------------------------------
 | base/_picture.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

picture {
	display: block;
}
