/*
 |-----------------------------------------------------------------------------
 | base/_iframe.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

iframe {
	border: 0;
	display: block;
	width: 100%;
}
