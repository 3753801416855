/*
 |-----------------------------------------------------------------------------
 | base/_fonts.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

/*
 |-----------------------------------------------------------------------------
 | Lato
 |-----------------------------------------------------------------------------
 |
 | Weights: Some description...
 |
 */

@include font-face('Lato', 'lato-100', 100);
@include font-face('Lato', 'lato-100-italic', 100, true);
@include font-face('Lato', 'lato-300', 300);
@include font-face('Lato', 'lato-300-italic', 300, true);
@include font-face('Lato', 'lato-400', 400);
@include font-face('Lato', 'lato-400-italic', 400, true);
@include font-face('Lato', 'lato-700', 700);
@include font-face('Lato', 'lato-700-italic', 700, true);
@include font-face('Lato', 'lato-900', 900);
@include font-face('Lato', 'lato-900-italic', 900, true);
