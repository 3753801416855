/*
 |-----------------------------------------------------------------------------
 | base/_legend.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

legend {
	display: block;
}
