/*
 |-----------------------------------------------------------------------------
 | base/_strong.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

strong {
	@include font-weight('bold');
}
