/*
 |-----------------------------------------------------------------------------
 | base/_anchor.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

a {
	text-decoration: none;

	p & {
		@include hyperlink($brand-1);
	}
}
