/*
 |-----------------------------------------------------------------------------
 | base/_lists.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

$color: $brand-1;
$size: 8px;

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;

	&:not([class]) {
		li {
			border-bottom: 1px dashed $color;
			color: $color;
			font-size: clamp(1.6rem, 5vw, 2.3rem);
			line-height: 1.4;
			padding: div($grid-gap, 4) 0;
			position: relative;
		}
	}
}

ol:not([class]) {
	margin: div($grid-gap, 2) 0 mul($grid-gap, 1.25) div($grid-gap, 2);

	li {
		counter-increment: step-counter;

		&::before {
			@include absolute($top: 0, $left: mul($grid-gap, -1.25));
			color: $color;
			content: counter(step-counter) '.';
		}
	}
}

ul:not([class]) {
	margin: div($grid-gap, 2) 0 mul($grid-gap, 1.5) div($grid-gap, 2);

	li {
		&::before {
			@include absolute($left: div($grid-gap, -2));
			content: '-';
			height: $size;
			width: $size;
		}
	}
}

dl {
	margin: 0;
}

dd {
	margin-left: 0;
}
