/*
 |-----------------------------------------------------------------------------
 | base/_fieldset.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

fieldset {
	border: 0;
	padding: 0;
}
