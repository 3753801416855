/*
 |-----------------------------------------------------------------------------
 | base/_body.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

body {
	@include font($family: 'stack-1', $size: 1.6rem, $weight: 'normal');
	color: $brand-3;
	position: relative;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
}
