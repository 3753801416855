/*
 |-----------------------------------------------------------------------------
 | base/_label.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

label {
	cursor: pointer;
}
