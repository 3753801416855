/*
 |-----------------------------------------------------------------------------
 | base/_address.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

address {
	font-style: normal;
}
