/*
 |-----------------------------------------------------------------------------
 | base/_button.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

button,
[type='button'],
[type='reset'],
[type='submit'] {
	appearance: none;
}

button {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	line-height: 1;
	padding: 0;

	&:focus {
		outline: 0;
	}
}
