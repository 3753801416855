/*
 |-----------------------------------------------------------------------------
 | base/_img.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

img {
	display: block;
	height: auto;
	max-width: 100%;
}
