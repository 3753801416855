/*
 |-----------------------------------------------------------------------------
 | base/_figure.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

figure {
	margin: 0;
}
