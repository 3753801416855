/*
 |-----------------------------------------------------------------------------
 | base/_box-sizing.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

*,
*::after,
*::before {
	box-sizing: inherit;
}
