/*
 |-----------------------------------------------------------------------------
 | base/_em.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

em {
	font-style: italic;
}
