/*
 |-----------------------------------------------------------------------------
 | base/_html.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

html {
	box-sizing: border-box;
	font-size: 62.5%;
	line-height: 1;
}
