/*
 |-----------------------------------------------------------------------------
 | base/_cite.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

cite {
	font-style: normal;
}
