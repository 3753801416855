/*
 |-----------------------------------------------------------------------------
 | base/_table.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

table {
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
